import { toast } from "react-toastify";
import { deleteItems, updateStateData } from "../Store/actions";
import store from "../Store/store";
import { loadCampaigns } from "./campaignRequests";
import {
  checkTodayDate,
  dateInCorrectTimezone,
  dbFormatDate,
  formatToStandardDate,
  isValidFileName,
  standardFormatToday,
  swapKeyValues,
  validateEmailFormat,
} from "./utils";
import { checkWorkSpaceFromCookies } from "./userManagement";
import platformConfig from "../platformConfig";
import { sendApiRequest, sendApiRequestWithFile } from "./requestConfig";

const matchPictureRecords = (item) => {
  const currentStateData = store.getState().userSelections;
  const appliedFilters = currentStateData.pic_list_filters;

  let itemMatch = false;

  // pic name filter
  if (appliedFilters.pic_name.length !== 0) {
    appliedFilters.pic_name.forEach((filterWord) => {
      if (item.name === filterWord.label) {
        itemMatch = true;
      }
    });
  }

  // pic company name filter
  if (appliedFilters.pic_company_filter.length !== 0) {
    appliedFilters.pic_company_filter.forEach((filterWord) => {
      if (item.picture_company_name === filterWord.label) {
        itemMatch = true;
      }
    });
  }

  // when duration/progress filter is applied
  if (appliedFilters.status_filter !== "" && appliedFilters.status_filter !== "") {
    appliedFilters.status_filter.forEach((filterWord) => {
      if (item.status === filterWord.label) {
        itemMatch = true;
      }
    });
  }

  return itemMatch;
};

export const filterPictures = () => {
  const currentStateData = store.getState().userSelections;
  let filteredData = [];

  //filter live campaigns
  if (typeof currentStateData.pictures_list !== "undefined" && currentStateData.pictures_list.length !== 0) {
    currentStateData.pictures_list.forEach((item) => {
      const checkMatch = matchPictureRecords(item);
      if (checkMatch === true) {
        filteredData.push(item);
      }
    });
  }

  store.dispatch(
    updateStateData({
      filtered_pictures_list: {
        pictures_list: filteredData,
      },
      pics_list_filter_applied: true,
    })
  );

  return true;
};
// load pictures list
export const loadPictures = async () => {
  const currentStateData = store.getState().userSelections;
  const checkPictures = await sendApiRequest("pictures", "", "GET");
  let response = {};
  if (checkPictures.success === true) {
    let picturesList = [];
    let picNamesList = [];
    let picCompanyNamesList = [];

    /*need to restructure pictures response as there's a lot of extra info returned in the response.*/
    if (checkPictures.response.length !== 0) {
      //   loop through the pictures response
      checkPictures.response.forEach((picture) => {
        //decide campaign status by comparing current date with start and end date of campaign.
        const currentDate = standardFormatToday();
        const picStartDate = formatToStandardDate(picture.date_start);
        const picEndDate = formatToStandardDate(picture.date_end);
        let picStatus = "";
        // status = Scheduled
        if (currentDate < picStartDate) {
          picStatus = "Scheduled";
        }
        // status = Completed
        if (currentDate > picEndDate) {
          picStatus = "Completed";
        }
        //status = Active
        if (currentDate > picStartDate && currentDate < picEndDate) {
          picStatus = "Active";
        }
        picturesList.push({
          id: picture.id,
          name: picture.content,
          retailer: typeof picture.retailer !== "undefined" ? picture.retailer.name : "",
          id_advertiser: picture.advertiser,
          stores: picture.count_stores,
          start_date: picture.date_start,
          end_date: picture.date_end,
          last_edit_by: picture.last_edit,
          status: picStatus,
          picture_company_name: picture.company.name,
          picture_company_id: picture.company.id,
          selected_store_options: picture.stores.map((storeInfo) => {
            return { label: storeInfo.name, id: storeInfo.id };
          }),
        });
        picNamesList.push({ id: picture.id, label: picture.content });
        picCompanyNamesList.push({ id: picture.company.id, label: picture.company.name });
      });
    }
    // save to the redux state
    store.dispatch(
      updateStateData({
        pictures_list: picturesList.sort((next, prev) => {
          return formatToStandardDate(prev.start_date) - formatToStandardDate(next.start_date);
        }),
        pic_names_list: picNamesList,
        pic_company_name_list: picCompanyNamesList,
      })
    );
    // check if filters are already applied, then we need to refresh the filtered list as well.
    if (
      typeof currentStateData.pics_list_filter_applied !== "undefined" &&
      typeof currentStateData.pic_list_filters !== "undefined" &&
      currentStateData.pics_list_filter_applied === true
    ) {
      filterPictures();
    }
    response = {
      success: true,
    };
  } else {
    response = {
      success: false,
    };
  }
  return response;
};
const validatePicsInfo = (validateFor) => {
  const currentStateData = store.getState().userSelections;
  let error = false;
  let errorMessages = {};
  // clip file
  const picFile =
    typeof document.getElementById("pictureUploadButton").files !== "undefined" &&
    document.getElementById("pictureUploadButton").files.length !== 0
      ? document.getElementById("pictureUploadButton").files[0]
      : "";
  // start date
  const picStartDate =
    typeof currentStateData.new_pic_data !== "undefined" &&
    typeof currentStateData.new_pic_data.pic_start_date !== "undefined"
      ? currentStateData.new_pic_data.pic_start_date
      : "";
  // end date
  const picEndDate =
    typeof currentStateData.new_pic_data !== "undefined" &&
    typeof currentStateData.new_pic_data.pic_end_date !== "undefined"
      ? currentStateData.new_pic_data.pic_end_date
      : "";
  // provided by
  const providedBy =
    typeof currentStateData.new_pic_data !== "undefined" &&
    typeof currentStateData.new_pic_data.pic_provided_by !== "undefined"
      ? currentStateData.new_pic_data.pic_provided_by
      : {};
  // stores
  const picStores = typeof currentStateData.picture_stores !== "undefined" ? currentStateData.picture_stores : [];

  const manualPicProvided =
    typeof currentStateData.new_pic_data !== "undefined" &&
    typeof currentStateData.new_pic_data.manual_pic_provided_by !== "undefined"
      ? currentStateData.new_pic_data.manual_pic_provided_by
      : false;

  const manualPicProvidedByValue =
    typeof currentStateData.new_pic_data !== "undefined" &&
    typeof currentStateData.new_pic_data.picture_brand_company_name !== "undefined"
      ? currentStateData.new_pic_data.picture_brand_company_name
      : "";
  //check if file is selected or not
  if ((picFile === "" && validateFor !== "update") || (!picFile instanceof File && validateFor !== "update")) {
    error = true;
    errorMessages = {
      ...errorMessages,
      pic_file: "Please select the picture file",
    };
  } else {
    if (picFile instanceof File) {
      //validate the file name
      const validateFileName = isValidFileName(picFile.name);
      const checkFileType = picFile.type;
      if (!validateFileName) {
        error = true;
        errorMessages = {
          ...errorMessages,
          pic_file: "Invalid file name. It should consist only of alphanumeric characters separated by underscores.",
        };
      } else {
        if (!platformConfig.valid_file_types.includes(checkFileType)) {
          error = true;
          errorMessages = {
            ...errorMessages,
            pic_file: `Invalid file type. Allowed types are: ${platformConfig.valid_file_types.join(", ")}.`,
          };
        }
      }
    }
  }

  // check start date
  if (picStartDate === "") {
    error = true;
    errorMessages = {
      ...errorMessages,
      pic_start_date: "Please select the start date for the picture",
    };
  }
  // check end date
  if (picEndDate === "") {
    error = true;
    errorMessages = {
      ...errorMessages,
      pic_end_date: "Please select the end date for the picture",
    };
  }
  // validate that start should not be after end date and vice versa
  if (picStartDate !== "" && picEndDate !== "") {
    const formattedStartDate = picStartDate; // dateInCorrectTimezone();
    const formattedEndDate = picEndDate; // dateInCorrectTimezone(picEndDate);
    //start/end should not be after end date
    if (formattedEndDate < formattedStartDate) {
      error = true;
      errorMessages = {
        ...errorMessages,
        pic_end_date: "End date must be after the start date",
      };
    }
  }
  // check provided by
  if (Object.keys(providedBy).length === 0 && manualPicProvided === false) {
    error = true;
    errorMessages = {
      ...errorMessages,
      provided_by: "Please select the company",
    };
  }
  if (manualPicProvidedByValue === "" && manualPicProvided === true) {
    error = true;
    errorMessages = {
      ...errorMessages,
      picture_brand_company_name: "Please enter the brand company name",
    };
  }

  // check stores
  if (picStores.length === 0) {
    error = true;
    errorMessages = {
      ...errorMessages,
      pic_stores: "Please select the stores",
    };
  }

  // show error message (if error === true)
  if (error === true) {
    toast("Please fill in valid information", { type: "error" });
    return {
      error: true,
      errorMessages,
    };
  } else {
    const formData = new FormData();
    const storesArr = currentStateData.stores_list.filter((store) => {
      return picStores.includes(store.name) ? store : false;
    });
    const retailerIds = currentStateData.stores_list
      .filter((store) => {
        return picStores.includes(store.name);
      })
      .map((storeRecord) => {
        return storeRecord.retailer_id;
      });

    let dataObject = {
      date_start: dbFormatDate(new Date(picStartDate)),
      date_end: dbFormatDate(new Date(picEndDate)),
      add_new_brand: manualPicProvided,
      count_stores: storesArr.length,
      weekdays: [0, 1, 2, 3, 4, 5, 6],
      stores: storesArr,
      retailer_ids: retailerIds,
    };

    if (manualPicProvided === false) {
      dataObject = {
        ...dataObject,
        id_advertiser: providedBy.id,
      };
    } else {
      dataObject = {
        ...dataObject,
        new_brand_name: manualPicProvidedByValue,
      };
    }

    if (validateFor === "update") {
      dataObject = {
        ...dataObject,
        id: currentStateData.new_pic_data.id,
      };
    }

    // Append file field
    if (picFile instanceof File) {
      formData.append("file", picFile);
      dataObject = {
        ...dataObject,
        content: picFile.name,
      };
    } else {
      if (validateFor === "update") {
        dataObject = {
          ...dataObject,
          content: currentStateData.new_pic_data.content_name,
        };
      }
    }

    // return validated request body for the clips add/update operation
    return {
      error: false,
      pic_file_request: picFile instanceof File ? formData : "",
      picture_request: dataObject,
    };
  }
};
// to save pictures information at backend
export const savePicturesInformation = async (usedfor) => {
  const validateInfo = validatePicsInfo(usedfor);
  let successFlag = false;
  let picsResponse = {};
  if (validateInfo.error === true) {
    picsResponse = {
      ...picsResponse,
      success: false,
      errorMessages: validateInfo.errorMessages,
    };
  } else {
    const apiEndpoint = usedfor === "update" ? "pictures/update" : "pictures/add";
    const apiMethod = usedfor === "update" ? "PUT" : "POST";
    const apiRequestParam = validateInfo.picture_request;

    // we will upload the file first (if file request obj is returned from validate funtion)
    if (validateInfo.pic_file_request !== "") {
      // upload file to s3
      const savePicFile = await sendApiRequestWithFile(
        "api/bucket/picture/upload?company_id=" + validateInfo.picture_request.retailer_ids.toString(),
        validateInfo.pic_file_request,
        "POST"
      );
      // file upload successful, then save other clip information i.e., campaign, stores etc.
      if (typeof savePicFile.success !== "undefined" && savePicFile.success === true) {
        // send another request to save clips information, previous api call will only upload the clip
        const savePicInfo = await sendApiRequest(apiEndpoint, apiRequestParam, apiMethod);
        // validate whether clips info api succeeded or failed
        if (typeof savePicInfo.success !== "undefined" && savePicInfo.success === true) {
          successFlag = true;
        } else {
          successFlag = false;
        }
      } else {
        successFlag = false;
      }
    } else {
      // send request to save clips information, file upload api is not needed in this case because file request obj is sent as empty from validate function
      const savePicInfo = await sendApiRequest(apiEndpoint, apiRequestParam, apiMethod);
      // validate api succeeded or not
      if (typeof savePicInfo.success !== "undefined" && savePicInfo.success === true) {
        successFlag = true;
      } else {
        successFlag = false;
      }
    }

    if (successFlag === true) {
      const successMessage = usedfor === "update" ? "Picture updated successfully" : "Picture added successfully";
      picsResponse = {
        ...picsResponse,
        success: true,
        errorMessages: "",
      };
      loadPictures();
      toast(successMessage, { type: "success" });
    } else {
      toast("Sorry, Something went wrong", { type: "error" });
      picsResponse = {
        ...picsResponse,
        success: false,
        errorMessages: "Sorry, Something went wrong",
      };
    }
  }

  return picsResponse;
};

export const resetPicturesData = () => {
  store.dispatch(
    deleteItems([
      "new_pic_data",
      "campaign_weeks",
      "picture_stores",
      "filtered_stores",
      "filtered_regions",
      "selected_region_ids",
      "selected_region_names",
      "selected_region_options",
      "selected_store_options",
    ])
  );
};
// fetch picture url
export const fetchPictureUrl = async (picName, companyId) => {
  const sendRequest = await sendApiRequest("api/bucket/picture/generate-url/" + picName + "/" + companyId, {}, "GET");
  if (typeof sendRequest.success !== "undefined" && sendRequest.success === true) {
    return {
      success: true,
      pic_file_url: sendRequest.response,
    };
  } else {
    return {
      success: false,
      message: "Sorry, something went wrong",
    };
  }
};

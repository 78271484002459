import { toast } from "react-toastify";
import { updateStateData } from "../Store/actions";
import store from "../Store/store";
import { allowedNavOptions } from "./userManagement";

function deleteCookie(cookieName) {
  document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

function createCookie(cookieName, cookieValue) {
  let date = new Date();
  date.setTime(date.getTime() + 2 * 60 * 60 * 1000); //cookie will be valid for 2 hours
  document.cookie = cookieName + "=" + cookieValue + "; expires = " + date.toGMTString();
}

//fetch auth token from cookies
export const fetchToken = () => {
  if (typeof document.cookie.split("; ").find((row) => row.startsWith("authToken")) !== "undefined") {
    return document.cookie
      .split("; ")
      .find((row) => row.startsWith("authToken"))
      .split("=")[1];
  }
  return "";
};

// to logout
export const logout = () => {
  deleteCookie("authToken");
  deleteCookie("userPermissions");
  deleteCookie("workspace_options");
  deleteCookie("current_workspace");
  deleteCookie("userRole");

  window.location.href = "/";
};

export const setLoadingStatus = (status) => {
  //update redux state to start a loader whenever a new request is send from the application
  store.dispatch(updateStateData({ loading: status }));
};

export const sendApiRequestWithFile = async (apiPath, requestBody, requestMethod) => {
  const authToken = fetchToken();
  const requestHeaders = new Headers();
  //auth token must be added to headers object (If available)
  if (authToken !== "") {
    requestHeaders.append("Authorization", "Bearer " + authToken);
  }
  setLoadingStatus(true);
  //send request
  const checkResponse = await fetch(process.env.REACT_APP_JAVA_API_URL + apiPath, {
    method: requestMethod,
    mode: "cors",
    headers: requestHeaders,
    body: requestMethod === "GET" ? null : requestBody,
  });

  if (checkResponse.status !== 200) {
    setLoadingStatus(false);
    //redirect back to login screen when status is 401 (unauthorised) or 500 (internal server error)
    if (checkResponse.status === 401 || checkResponse.status === 500) {
      // window.location.href = "/";
    }
    return {
      success: false,
      message: "Sorry, something went wrong",
    };
  } else {
    setLoadingStatus(false);
    let response = {};
    if (requestMethod !== "DELETE") {
      response = await checkResponse.json();
    }
    return {
      success: true,
      response: response,
    };
  }
};

//common function to send api request and returning the response back to the calling function
export const sendApiRequest = async (apiPath, requestBody, requestMethod, loaderControl = false) => {
  let authToken = fetchToken();
  let requestHeaders = new Headers();
  requestHeaders.append("Content-Type", "application/json");

  if (authToken !== "") {
    requestHeaders.append("Authorization", "Bearer " + authToken);
  }

  let retry = false;
  let retryCount = 0;
  // if api call fails in first attempt, system will retry the api one more time.
  do {
    setLoadingStatus(true);
    // api request
    const checkResponse = await fetch(process.env.REACT_APP_JAVA_API_URL + apiPath, {
      method: requestMethod,
      mode: "cors",
      headers: requestHeaders,
      body: requestMethod === "GET" ? null : JSON.stringify(requestBody),
    });
    // response check
    if (checkResponse.status === 401 || checkResponse.status === 500) {
      retry = true;
      retryCount++;

      if (retryCount > 1) {
        // If retry fails, redirect to login page
        setLoadingStatus(false);
        // window.location.href = "/";
        return {
          success: false,
          message: "Unauthorized. Redirecting to login page...",
        };
      } else {
        // Retry after fetching a new token
        authToken = fetchToken();
        requestHeaders.set("Authorization", "Bearer " + authToken);
      }
    } else if (checkResponse.status !== 200) {
      setLoadingStatus(false);
      // Handle other error statuses
      return {
        success: false,
        message: "Sorry, something went wrong",
      };
    } else {
      // Reset retry on successful response
      retry = false;

      if (loaderControl === false) {
        setLoadingStatus(false);
      }
      let response = {};
      if (requestMethod !== "DELETE") {
        response = await checkResponse.json();
      }
      return {
        success: true,
        response: response,
      };
    }
  } while (retry);
};

// fetch workspace list for the logged user
export const fetchWorkSpaceList = async (navigate) => {
  const fetchWorkSpaceDetail = await sendApiRequest("workspaces", {}, "GET");
  //check if data variable has token or not
  if (typeof fetchWorkSpaceDetail.success !== "undefined" && fetchWorkSpaceDetail.success === true) {
    const workSpaceItems = fetchWorkSpaceDetail.response;
    const showWorkspaceList = workSpaceItems.length > 1 ? true : false;
    // show popup
    store.dispatch(
      updateStateData({
        show_workspace_list: showWorkspaceList,
        workspace_options: workSpaceItems,
      })
    );
    createCookie("workspace_options", JSON.stringify(workSpaceItems));
    // let the user go to it's default workspace
    if (showWorkspaceList === false) {
      checkAuthorizedUserDetails(navigate);
    }
  } else {
    return {
      success: false,
    };
  }
};

export const checkAuthorizedUserDetails = async (navigate) => {
  const fetchUserDetail = await sendApiRequest("users/info", {}, "GET");

  if (typeof fetchUserDetail.success !== "undefined" && fetchUserDetail.success === true) {
    /*save permissions in the cookies, so we can use it even after page refresh. 
      We can't save it to redux because redux state will get reset after page reload.*/
    const userDetail = {
      user_email: fetchUserDetail.response.email,
      user_name: fetchUserDetail.response.username,
      user_role: fetchUserDetail.response.role_name === null ? "Customized" : fetchUserDetail.response.role_name,
      force_reset_password: false,
      current_workspace: fetchUserDetail.response.current_workspace,
      user_type: fetchUserDetail.response.user_type,
    };

    createCookie("userPermissions", JSON.stringify(fetchUserDetail.response.permissions));
    createCookie("userRole", JSON.stringify(userDetail.user_role));
    createCookie("userDetail", JSON.stringify(userDetail));

    createCookie("current_workspace", JSON.stringify(userDetail.current_workspace));

    store.dispatch(updateStateData(userDetail));

    const landingPage = await allowedNavOptions({ pathname: "/" });
    let redirectTo =
      typeof landingPage.navItems !== "undefined" && landingPage.navItems.length !== 0
        ? landingPage.navItems[0].path
        : "/cap-overview";
    //check whether we need to force user to reset the password
    if (userDetail.force_reset_password === true) {
      redirectTo = "/accounts/reset-password";
    }

    if (window.location.pathname === redirectTo) {
      // this condition is added to make sure that correct workspace changes reflect properly
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      navigate(redirectTo);
    }

    return {
      success: true,
      user_detail: userDetail,
    };
  } else {
    window.location.href("/");
    return {
      success: false,
    };
  }
};

// Authentication (API will return a token if provided credentials are valid.)
export const authorizeCredentials = async (userCredentials, navigate) => {
  const authenticateUser = await sendApiRequest("authenticate", userCredentials, "POST", true);
  //check if data variable has token or not
  if (typeof authenticateUser.success !== "undefined" && authenticateUser.success === true) {
    /*save token in the cookies, so we can use it even after page refresh. 
      We can't save it to redux because redux state will get reset after page reload.*/
    createCookie("authToken", authenticateUser.response.access_token);
    // fetch user details and save user permissions in cookies (cookies are preferred to handle use cases related to page refresh)
    setTimeout(() => {
      fetchWorkSpaceList(navigate);
    }, 1000);
  } else {
    toast("Please check your username and password and try again.", { type: "error" });
  }
};

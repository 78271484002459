import React from "react";
import {
  Box,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  Paper,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {
  HtmlTooltip,
  StyledCheckbox,
  StyledDarkRedInformationTypography,
  StyledGreyInfoBox,
  StyledInfoBox,
  StyledInformationSpan,
  StyledMainBox,
} from "../../../components/commonComponents/StyledComponents/styled";
import UploadButton from "../../../components/commonComponents/FileUpload/UploadButton";
import DateInputField from "../../../components/commonComponents/TextInputs/DateInputs";
import SelectBox from "../../../components/commonComponents/Selectors/SelectBox";
import TextInputField from "../../../components/commonComponents/TextInputs/TextInputField";
import { Close, Info, Save } from "@mui/icons-material";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import { loadCampaigns } from "../../../api_calls/campaignRequests";
import { useDispatch, useSelector } from "react-redux";
import { loadStoresFromCampaign, saveClipsInformation } from "../../../api_calls/clipRequests";
import { updateStateData } from "../../../Store/actions";
import {
  checkStatus,
  fetchWeekNumbers,
  formatToStandardDate,
  standardFormatToday,
  weekDayOptions,
  weekDayNames,
} from "../../../api_calls/utils";
import StoreHierarchy from "./StoreHierarchy";
import ErrorMessageText from "../../../components/commonComponents/Errors/ErrorMessageText";
import SingleSelect from "../../../components/commonComponents/Selectors/SingleSelect";
import MultiSelect from "../../../components/commonComponents/Selectors/MultiSelect";
import NoSortMultiSelect from "../../../components/commonComponents/Selectors/NoSortMultiSelect";
import moment from "moment-timezone";
import { end } from "@popperjs/core";
// import { set } from "immer/dist/internal.js";

const AddNewClip = ({ open, onClose, size, usedFor, closeModal, clipInfo }) => {
  const currentStateData = useSelector((state) => state.userSelections);
  const dispatchAction = useDispatch();
  const campaignNameListOptions =
    typeof currentStateData.campaign_list !== "undefined" &&
    typeof currentStateData.campaign_list.active_campaign_name_list !== "undefined"
      ? currentStateData.campaign_list.active_campaign_name_list
      : [];
  const campaignDatesList =
    typeof currentStateData.campaign_list !== "undefined" &&
    typeof currentStateData.campaign_list.campaign_dates_list !== "undefined"
      ? currentStateData.campaign_list.campaign_dates_list
      : [];
  const [fieldErrors, setFieldErrors] = React.useState({});
  const [minSelectableDateForClip, setMinSelectableDateForClip] = React.useState(standardFormatToday());
  const [showStartDateConfirmation, setStartDateConfirmation] = React.useState(false);
  const [showEndDateConfirmation, setEndDateConfirmation] = React.useState(false);
  const [weekDays, setWeekDays] = React.useState(false);


  const handleFieldChange = (clipData) => {
    const selectedClipData = typeof currentStateData.new_clip_data !== "undefined" ? currentStateData.new_clip_data : {};
    dispatchAction(
      updateStateData({
        new_clip_data: { ...selectedClipData, ...clipData },
      })
    );
    // dispatchAction(
    //   updateStateData({
    //     new_clip_data: clipData,
    //   })
    // );
  };

  const calculateWeeks = (datesData) => {
    const clipStart = typeof datesData.clip_start_date !== "undefined" ? datesData.clip_start_date : "";
    const clipEnd = typeof datesData.clip_end_date !== "undefined" ? datesData.clip_end_date : "";
    let weeks = [];
    if (clipStart !== "" && clipEnd !== "") {
      weeks = fetchWeekNumbers(clipStart, clipEnd);
      // auto select all the weeks
    }
    return weeks;
  };

  function getWeekdaysInRange(startDate, endDate) {
    startDate = formatToStandardDate(startDate);
    endDate = formatToStandardDate(endDate);

    // Add +1 because the calc of the days misses 1 day
    // ex: 29.08.24 - 01.09.24 = 4 weekdays -> thursday, friday, saturday, sunday
    // normal calc only calcs 3 days...
    const daysInSelectedTimeperiod = Math.round((endDate - startDate) / (1000 * 3600 * 24) + 1); 
    
    // Check if the selected period is less than 6 days, cause only then u need the edited weekdayOptions object, otherwise we could use all 7 weekdays
    if (daysInSelectedTimeperiod < 7) {
      const clipStart = typeof startDate !== "undefined" ? startDate : "";
      const clipEnd = typeof endDate !== "undefined" ? endDate : "";
      const date = formatToStandardDate(clipStart.getTime());
      const weekDayNumbersInRange = [];
      const weekDayNamesAccordingToNumbers = [];
      var weekDayObj = [{}];

      // as long as the starting date is older than the ending date
      while (date <= clipEnd) {
        // each weekday gets a number assinged Sun 0 -> Sat 6
        weekDayNumbersInRange.push(date.getDay());
        date.setDate(date.getDate() + 1);
      }
      weekDayNumbersInRange.forEach(day => {
        // Push all of the actual week day names according to the previous gained numbers into an array
        weekDayNamesAccordingToNumbers.push(weekDayNames[day]);
      });
      // compare the names which just got pushed into the new array with the weekday options labels and push that into a new obj
      weekDayOptions.forEach(option => {
        weekDayNamesAccordingToNumbers.forEach(dayName => {
          if (option.label === dayName) {
            weekDayObj.push(option);
          }
        })
      });
      // erase empty entries
      weekDayObj = weekDayObj.filter(item => Object.keys(item).length > 0)

      // check if a previous entered date got changed and update the weekdays according to it
      if ( typeof currentStateData.new_clip_data.clip_week_day_options != "undefined" && currentStateData.new_clip_data.clip_week_day_options.length > 0 ) {
        currentStateData.new_clip_data.clip_week_day_options.forEach(option => {
          if (!weekDayObj.some(item => item.label === option.label)) {
            // remove the weekday out of the object array which is not possible anymore due to the date range change
            var filtered_week_day_options = currentStateData.new_clip_data.clip_week_day_options.filter(function(el) { return el !== option; }); 
            var filtered_week_days = currentStateData.new_clip_data.clip_week_days.filter(function(el) { return el !== option.label; }); 

            const selectedData = {
              clip_week_days: filtered_week_days,
              clip_week_day_options: filtered_week_day_options,
            };
            // setcurrentStateData.new_clip_data(selectedData);
            handleFieldChange(selectedData);
          }
        })
      }
      if (JSON.stringify(weekDays) !== JSON.stringify(weekDayObj)) {
        setWeekDays(weekDayObj);
      }
      // console.log(weekDays);
      return weekDays;
    }
    else {
      return weekDayOptions
    }
  }


  const setClipStartDateConstraint = (campaignStartDate) => {
    const today = standardFormatToday(); //moment().tz(platformConfig.timezone).startOf("day");
    const campaignStart = formatToStandardDate(campaignStartDate);
    // if campaign start date = today or campaign start date is in past, we have to add one day to the start date
    if (today.getTime() === campaignStart.getTime() || today > campaignStart) {
      // const nextDay = new Date(today);
      // nextDay.setDate(today.getDate() + 1);
      setMinSelectableDateForClip(today);
    }

    // campaign start date is in future
    if (campaignStart > today) {
      setMinSelectableDateForClip(campaignStart);
    }
  };

  // set min selectable date for the clip start date

  React.useEffect(() => {
    // fetch campaign list
    loadCampaigns();
    if (typeof clipInfo !== "undefined" && Object.keys(clipInfo).length !== 0) {
      const clipStatus = checkStatus(clipInfo.start_date, clipInfo.end_date);
      calculateWeeks({
        clip_start_date: formatToStandardDate(clipInfo.start_date),
        clip_end_date: formatToStandardDate(clipInfo.end_date),
      });
      handleFieldChange({
        id: clipInfo.id,
        content_name: clipInfo.name,
        campaign_name: clipInfo.campaign,
        campaign_id: clipInfo.campaign_id,
        clip_start_date: formatToStandardDate(clipInfo.start_date),
        clip_end_date: formatToStandardDate(clipInfo.end_date),
        clip_weeks: clipInfo.selected_weeks,
        clip_week_days: clipInfo.selected_days,
        campaign_start_date: campaignDatesList[clipInfo.campaign_id].campaign_start_date,
        campaign_end_date: campaignDatesList[clipInfo.campaign_id].campaign_end_date,
        clip_week_day_options: weekDayOptions.filter((dayOption) => {
          if (clipInfo.selected_days.includes(dayOption.label)) {
            return true;
          } else {
            return false;
          }
        }),
        clip_contacts: clipInfo.campaign_contacts,
        clip_volume: clipInfo.clip_volume,
        clip_unlimited: clipInfo.clip_unlimited,
        clip_start_end_status: clipStatus,
      });
      loadStoresFromCampaign(clipInfo.campaign_id)
      setClipStartDateConstraint(campaignDatesList[clipInfo.campaign_id].campaign_start_date);
    } else {
      handleFieldChange({
        clip_unlimited: false,
        clip_volume: "190",
      });
    }
  }, []);
  return (
    <Dialog
      open={open}
      onClose={(e) => {
        e.preventDefault();
      }}
      fullWidth={true}
      maxWidth={size}
    >
      <DialogTitle>{usedFor === "update" ? "Edit : " + clipInfo?.name : "Add Clips"} </DialogTitle>
      <DialogContent>
        <StyledMainBox component="main">
          <Box>
            {/* ------- file upload -------- */}
            <Stack direction={"row"} spacing={4}>
              <UploadButton uploadButtonId={"uploadClipFile"} uploadLabel={"Select Clip*"} acceptedFileType={"video/mp4"} />
            </Stack>

            {/* ------- error message ------ */}
            {typeof fieldErrors.clip_file !== "undefined" && fieldErrors.clip_file !== "" && (
              <>
                <br />
                <ErrorMessageText message={fieldErrors.clip_file} />
              </>
            )}

            <Box height={40} />
            {/* -------- campaign selection ---------- */}
            <Stack direction={"row"} spacing={4}>
              {/* -------- Select Campaign ------- */}
              <SingleSelect
                label="Campaign*"
                elemId={"clipsCampaignList"}
                enableFullWidth={true}
                options={campaignNameListOptions}
                defaultVal={
                  typeof currentStateData.new_clip_data !== "undefined" &&
                  typeof currentStateData.new_clip_data.campaign_name !== "undefined"
                    ? {
                        id: currentStateData.new_clip_data.campaign_id,
                        label: currentStateData.new_clip_data.campaign_name,
                      }
                    : ""
                }
                selectionCheck={(option, value) => option.id === value.id}
                onChangeCall={(selectedOption) => {
                  let selectedData = {
                    campaign_id: selectedOption.id,
                    campaign_name: selectedOption.label,
                  };
                  if (campaignDatesList.length !== 0) {
                    selectedData = {
                      ...selectedData,
                      campaign_start_date: campaignDatesList[selectedOption.id].campaign_start_date,
                      campaign_end_date: campaignDatesList[selectedOption.id].campaign_end_date,
                    };
                  }
                  // setcurrentStateData.new_clip_data(selectedData);
                  handleFieldChange(selectedData);
                  setClipStartDateConstraint(campaignDatesList[selectedOption.id].campaign_start_date);
                  loadStoresFromCampaign(selectedOption.id);
                }}
                readOnlyFlag={
                  typeof currentStateData.new_clip_data !== "undefined" &&
                  typeof currentStateData.new_clip_data.clip_start_end_status !== "undefined" &&
                  ["Active", "Completed"].includes(currentStateData.new_clip_data.clip_start_end_status)
                    ? true
                    : false
                }
                fieldError={
                  typeof fieldErrors.clip_campaign !== "undefined" && fieldErrors.clip_campaign !== ""
                    ? fieldErrors.clip_campaign
                    : ""
                }
              />
            </Stack>
            {/* ------- start/end date -------- */}

            {typeof currentStateData.new_clip_data !== "undefined" &&
              typeof currentStateData.new_clip_data.campaign_name !== "undefined" && (
                <>
                  <Box height={40} />
                  <Stack direction={"row"} spacing={4}>
                    {/* ------ clip start date -------- */}
                    <DateInputField
                      fieldRequired={true}
                      elemId="startDate"
                      className="start_date_class"
                      label="Start Date*"
                      fieldName="start_date"
                      minSelectable={minSelectableDateForClip}
                      maxSelectable={
                        typeof currentStateData.new_clip_data !== "undefined" &&
                        typeof currentStateData.new_clip_data.campaign_end_date !== "undefined" &&
                        formatToStandardDate(currentStateData.new_clip_data.campaign_end_date)
                      }
                      defaultVal={
                        typeof currentStateData.new_clip_data !== "undefined" &&
                        typeof currentStateData.new_clip_data.clip_start_date !== "undefined"
                          ? currentStateData.new_clip_data.clip_start_date
                          : ""
                      }
                      showlabel={false}
                      disabled={
                        typeof currentStateData.new_clip_data !== "undefined" &&
                        typeof currentStateData.new_clip_data.clip_start_end_status !== "undefined" &&
                        ["Active", "Completed"].includes(currentStateData.new_clip_data.clip_start_end_status)
                          ? true
                          : false
                      }
                      fieldError={
                        typeof fieldErrors.clip_start_date !== "undefined" && fieldErrors.clip_start_date !== ""
                          ? fieldErrors.clip_start_date
                          : ""
                      }
                      onChangeCall={(selectedDate) => {
                        // informatory text will only be shown in case of edit only
                        if (currentStateData?.new_clip_data?.clip_unlimited === false && usedFor === "update") {
                          // intentionally used end date confirmation function because we have a special scenario for start date >> info message should appear in both edit/add action for start date if it's same as today
                          setEndDateConfirmation(true);
                        }

                        // informatory text will only be shown in case of edit only
                        setStartDateConfirmation(moment(selectedDate).isSame(standardFormatToday()));

                        const selectedData = {
                          clip_start_date: selectedDate,
                        };
                        // setcurrentStateData.new_clip_data(selectedData);
                        handleFieldChange(selectedData);
                        calculateWeeks({
                          ...currentStateData.new_clip_data,
                          clip_start_date: selectedDate,
                        });
                      }}
                    />

                    {/* -------- clip end date ------- */}
                    <DateInputField
                      fieldRequired={true}
                      elemId="endDate"
                      label="End Date*"
                      className="end_date_class"
                      fieldName="end_date"
                      defaultVal={
                        typeof currentStateData.new_clip_data !== "undefined" &&
                        typeof currentStateData.new_clip_data.clip_end_date !== "undefined"
                          ? currentStateData.new_clip_data.clip_end_date
                          : ""
                      }
                      showlabel={false}
                      disabled={
                        typeof currentStateData.new_clip_data !== "undefined" &&
                        typeof currentStateData.new_clip_data.clip_start_end_status !== "undefined" &&
                        ["Completed"].includes(currentStateData.new_clip_data.clip_start_end_status)
                          ? true
                          : false
                      }
                      minSelectable={(() => {
                        if (
                          currentStateData.new_clip_data &&
                          currentStateData.new_clip_data.clip_start_date &&
                          moment(currentStateData.new_clip_data.clip_start_date).isSameOrAfter(moment(), "day")
                        ) {
                          // If clip_start_date is in the future or today, set minSelectable to clip_start_date
                          return currentStateData.new_clip_data.clip_start_date;
                        } else if (
                          currentStateData.new_clip_data &&
                          currentStateData.new_clip_data.clip_start_date &&
                          moment(currentStateData.new_clip_data.clip_start_date).isSameOrBefore(moment(), "day")
                        ) {
                          // If clip_start_date is in the past or today, set minSelectable to today
                          return minSelectableDateForClip;
                        } else if (
                          typeof currentStateData.new_clip_data !== "undefined" &&
                          typeof currentStateData.new_clip_data.campaign_start_date !== "undefined"
                        ) {
                          return minSelectableDateForClip;
                        } else {
                          // If clip_start_date is in the past or undefined, set minSelectable to standardFormatToday()
                          return standardFormatToday();
                        }
                      })()}
                      maxSelectable={
                        typeof currentStateData.new_clip_data !== "undefined" &&
                        typeof currentStateData.new_clip_data.campaign_end_date !== "undefined" &&
                        formatToStandardDate(currentStateData.new_clip_data.campaign_end_date)
                      }
                      // enabledDates={(date) => date.getDay() === 0}
                      fieldError={
                        typeof fieldErrors.clip_end_date !== "undefined" && fieldErrors.clip_end_date !== ""
                          ? fieldErrors.clip_end_date
                          : ""
                      }
                      onChangeCall={(selectedDate) => {
                        // informatory text will only be shown in case of edit only
                        if (currentStateData?.new_clip_data?.clip_unlimited === false && usedFor === "update") {
                          setEndDateConfirmation(true);
                        } else {
                          setEndDateConfirmation(false);
                        }

                        const selectedData = {
                          clip_end_date: selectedDate,
                        };
                        // setcurrentStateData.new_clip_data(selectedData);
                        handleFieldChange(selectedData);
                        setTimeout(() => {
                          calculateWeeks({ ...currentStateData.new_clip_data, clip_end_date: selectedDate });
                        }, 1000);
                      }}
                    />
                  </Stack>
                  {(showStartDateConfirmation || showEndDateConfirmation) && (
                    <>
                      {showStartDateConfirmation && (
                        <>
                          <Box height={40} />
                          <StyledInfoBox>
                            <Typography variant="body2">
                              This clip is scheduled to start today. If the store is already open, the clip will begin
                              playing within the next hour. Be aware that data for today might be incomplete, affecting
                              later reports.
                            </Typography>
                          </StyledInfoBox>
                        </>
                      )}

                      {showEndDateConfirmation && (
                        <>
                          <Box height={40} />
                          <StyledInfoBox>
                            <Typography variant="body2">
                              When adjusting the dates of an existing clip, please note that the impression target is not
                              automatically updated. Remember to manually adjust the impression target for this clip, if
                              needed.
                            </Typography>
                          </StyledInfoBox>
                        </>
                      )}
                    </>
                  )}
                </>
              )}

            {/* ----------- unlimited checkbox --------- */}
            <Box height={50} />
            <Stack direction={"row"} spacing={4}>
              {/* -------- unlimited ------- */}
              <FormControlLabel
                control={
                  <StyledCheckbox
                    defaultChecked={
                      typeof currentStateData.new_clip_data !== "undefined" &&
                      typeof currentStateData.new_clip_data.clip_unlimited !== "undefined"
                        ? currentStateData.new_clip_data.clip_unlimited
                        : false
                    }
                    onChange={(e) => {
                      const selectedData = {
                        clip_unlimited: e.target.checked,
                      };
                      // setcurrentStateData.new_clip_data(selectedData);
                      handleFieldChange(selectedData);
                    }}
                  />
                }
                label="Unlimited"
              />
            </Stack>
            {/* --------- weeks, days and hour selection ---------- */}

            {typeof currentStateData.new_clip_data !== "undefined" &&
              typeof currentStateData.new_clip_data.clip_unlimited !== "undefined" &&
              currentStateData.new_clip_data.clip_unlimited === false && (
                <>
                  <Box height={40} />
                  <Stack direction={"row"} spacing={4}>
                    {/* -------- weeks numbers ------- */}
                    <NoSortMultiSelect
                      label="Calendar Weeks"
                      elemId="clipWeekNum"
                      enableFullWidth={true}
                      options={
                        typeof currentStateData.campaign_weeks !== "undefined" ? currentStateData.campaign_weeks : []
                      }
                      defaultVal={
                        typeof currentStateData.new_clip_data !== "undefined" &&
                        typeof currentStateData.new_clip_data.clip_weeks !== "undefined"
                          ? typeof currentStateData.campaign_weeks !== "undefined"
                            ? currentStateData.campaign_weeks.filter((weekInfo) => {
                                return currentStateData.new_clip_data.clip_weeks.includes(weekInfo.value);
                              })
                            : []
                          : []
                      }
                      selectionCheck={(option, value) => option.value === value.value}
                      onChangeCall={(selectedOption) => {
                        let selectedWeekNum = [];
                        // selectedOption.forEach((option) => {
                        //   selectedWeekNum.push(option.value);
                        // });

                        selectedOption.forEach((chosen) => {
                          if (chosen.value !== "all") {
                            selectedWeekNum.push(chosen.value);
                          } else {
                            currentStateData.campaign_weeks.forEach((week) => {
                              if (week.value !== "all") {
                                selectedWeekNum.push(week.value);
                              }
                            });
                          }
                        });

                        const selectedData = {
                          clip_weeks: selectedWeekNum,
                        };
                        // setcurrentStateData.new_clip_data(selectedData);
                        handleFieldChange(selectedData);
                      }}
                      fieldError={
                        typeof fieldErrors.clip_weeks !== "undefined" && fieldErrors.clip_weeks !== ""
                          ? fieldErrors.clip_weeks
                          : ""
                      }
                    />

                    {/* --------- week days -------- */}
                    <NoSortMultiSelect
                      label="Weekdays"
                      elemId="clipWeekDays"
                      enableFullWidth={true}
                      options={getWeekdaysInRange(currentStateData.new_clip_data.clip_start_date, currentStateData.new_clip_data.clip_end_date)}
                      defaultVal={
                        typeof currentStateData.new_clip_data !== "undefined" &&
                        typeof currentStateData.new_clip_data.clip_week_day_options !== "undefined"
                          // ? currentStateData.new_clip_data.clip_week_day_options
                          ? currentStateData.new_clip_data.clip_week_day_options
                          : []
                      }
                      selectionCheck={(option, value) => option.value === value.value}
                      onChangeCall={(selectedOption) => {
                        if(JSON.stringify(selectedOption) !== JSON.stringify(weekDays)){
                          console.log(selectedOption);
                        }
                        let selectedDays = [];
                        let selectedWeekDays = [];
                        selectedOption.forEach((day) => {
                          if (day.value === "all") {
                            weekDayOptions.forEach((weekDay) => {
                              if (weekDay.value !== "all") {
                                selectedDays.push(weekDay.label);
                                selectedWeekDays.push(weekDay);
                              }
                            });
                          } else {
                            selectedDays.push(day.label);
                            selectedWeekDays = selectedOption;
                          }
                        });
            
                        const selectedData = {
                          clip_week_days: selectedDays,
                          clip_week_day_options: selectedWeekDays,
                        };
                        // setcurrentStateData.new_clip_data(selectedData);
                        handleFieldChange(selectedData);
                      }}
                      fieldError={
                        typeof fieldErrors.clip_week_days !== "undefined" && fieldErrors.clip_week_days !== ""
                          ? fieldErrors.clip_week_days
                          : ""
                      }
                    />
                  </Stack>
                </>
              )}

            {/* --------- impressions, volume and stores --------- */}
            <Box height={40} />
            <Stack direction={"row"} spacing={4}>
              {/* -------- contacts ------- */}
              {typeof currentStateData.new_clip_data !== "undefined" &&
                typeof currentStateData.new_clip_data.clip_unlimited !== "undefined" &&
                currentStateData.new_clip_data.clip_unlimited === false && (
                  <TextInputField
                    fieldRequired={true}
                    elemId="campaignContacts"
                    label="Impressions"
                    fieldName="campaign_contacts"
                    showlabel={true}
                    defaultVal={
                      typeof currentStateData.new_clip_data !== "undefined" &&
                      typeof currentStateData.new_clip_data.clip_contacts !== "undefined"
                        ? currentStateData.new_clip_data.clip_contacts
                        : ""
                    }
                    inputType={"text"}
                    fieldError={
                      typeof fieldErrors.clip_contacts !== "undefined" && fieldErrors.clip_contacts !== ""
                        ? fieldErrors.clip_contacts
                        : ""
                    }
                    onChangeCall={(e) => {
                      e.preventDefault();
                      const selectedData = {
                        clip_contacts: e.target.value,
                      };
                      // setcurrentStateData.new_clip_data(selectedData);
                      handleFieldChange(selectedData);
                    }}
                  />
                )}

              {/* --------- volume -------- */}
              <TextInputField
                fieldRequired={true}
                elemId="volume"
                label="Volume"
                fieldName="volume"
                showlabel={true}
                defaultVal={
                  typeof currentStateData.new_clip_data !== "undefined" &&
                  typeof currentStateData.new_clip_data.clip_volume !== "undefined"
                    ? currentStateData.new_clip_data.clip_volume
                    : ""
                }
                inputType={"text"}
                fieldError={
                  typeof fieldErrors.clip_volume !== "undefined" && fieldErrors.clip_volume !== ""
                    ? fieldErrors.clip_volume
                    : ""
                }
                onChangeCall={(e) => {
                  e.preventDefault();
                  const selectedData = {
                    clip_volume: e.target.value,
                  };
                  // setcurrentStateData.new_clip_data(selectedData);
                  handleFieldChange(selectedData);
                }}
                iconProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ marginRight: "15px" }}>
                      <HtmlTooltip
                        title={
                          <Box width={300}>
                            <Typography variant="body2">
                              <strong>Volume Ranges:</strong> <br />
                              <Box height={10} />
                              <strong>{"> 200:"}</strong> Very loud, usually deterioration in sound quality <br />
                              <Box height={5} />
                              <strong>170 - 200: </strong> Normal loudness, usually high sound quality (recommended range){" "}
                              <br />
                              <Box height={5} />
                              <strong>{"< 170:"} </strong> Very quiet, usually too quiet for store environment <br />
                            </Typography>
                          </Box>
                        }
                      >
                        <IconButton>
                          <Info aria-label="volumeToolTip" />
                        </IconButton>
                      </HtmlTooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            {/* ---------- stores selection ---------- */}
            {typeof currentStateData.campaign_stores_list !== "undefined" && (
              <>
                <Box height={40} />
                <StoreHierarchy
                  fieldNames={{ stores: "clip_stores" }}
                  fieldError={
                    typeof fieldErrors.clip_stores !== "undefined" && fieldErrors.clip_stores !== ""
                      ? fieldErrors.clip_stores
                      : ""
                  }
                  selectionInfo={clipInfo}
                  usedFor={"clips"}
                />
              </>
            )}

            {/* ----------- unlimited checkbox --------- */}
            <Box height={40} />
            <Stack direction={"row"} spacing={4}>
              {usedFor === "update" && (
                <StyledDarkRedInformationTypography variant="body2">
                  Last Modified:
                  <StyledInformationSpan
                    style={{
                      color: "#2D2D37",
                      fontWeight: "bold",
                      marginLeft: "5px",
                    }}
                  >
                    {clipInfo.last_edit_by}
                  </StyledInformationSpan>
                </StyledDarkRedInformationTypography>
              )}
            </Stack>
          </Box>
        </StyledMainBox>
      </DialogContent>
      <Box height={20} />
      <DialogActions>
        <Stack direction={"row"} spacing={2}>
          <CloseButton
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
            label="Cancel"
            icon={<Close />}
          />
          <ActionButton
            onClick={(e) => {
              e.preventDefault();
              setFieldErrors({});
              saveClipsInformation(usedFor).then((info) => {
                if (info.success === false) {
                  if (info.errorMessages !== "") {
                    setFieldErrors(info.errorMessages);
                  }
                } else {
                  closeModal();
                }
              });
            }}
            label={usedFor === "update" ? "Update" : "Save"}
            icon={<Save />}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewClip;

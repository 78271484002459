import React from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { StyledCheckbox, StyledMainBox, StyledStack } from "../../../components/commonComponents/StyledComponents/styled";
import TextInputField from "../../../components/commonComponents/TextInputs/TextInputField";
import TabularList from "../../../components/commonComponents/TabularLists/TabularList";
import SelectBox from "../../../components/commonComponents/Selectors/SelectBox";
import { permissionConfig } from "./permissionScopes";
import { useDispatch, useSelector } from "react-redux";
import { updateStateData } from "../../../Store/actions";
import { capitalizeAndReplaceUnderScore } from "../../../api_calls/utils";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import { Close, Password, Save } from "@mui/icons-material";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";
import {
  checkCompanyId,
  checkUserDetailsFromCookie,
  fetchCompanies,
  saveUserInformation,
} from "../../../api_calls/userManagement";
import { toast } from "react-toastify";
import { loadCampaigns } from "../../../api_calls/campaignRequests";
import SingleSelect from "../../../components/commonComponents/Selectors/SingleSelect";
import MultiSelect from "../../../components/commonComponents/Selectors/MultiSelect";
import { filterRetailerCampaigns } from "../../../api_calls/campaigns";
import { StyledTableCell } from "../../../components/commonComponents/TabularLists/styles";

const AddUser = ({ open, onClose, size, usedFor, userInfo, closeModal }) => {
  const restrictedAreas = permissionConfig.scopes;
  const [selectedRolePermissions, setSelectedRolePermissions] = React.useState();
  const [userDetails, setUserDetails] = React.useState(userInfo);
  const [userData, setUserData] = React.useState({});
  const [fieldError, setFieldError] = React.useState({});

  const currentStateData = useSelector((state) => state.userSelections);
  const roleAndPermissions = typeof currentStateData.role_list !== "undefined" ? currentStateData.role_list : {};
  const dispatchAction = useDispatch();

  const savePermissions = (scope, permissionSelections) => {
    const userPermissions = {
      ...selectedRolePermissions,
      [scope]: {
        ...selectedRolePermissions[scope],
        ...permissionSelections,
      },
    };
    setSelectedRolePermissions(userPermissions);
    dispatchAction(
      updateStateData({
        newUserPermissions: userPermissions,
      })
    );
  };

  const handleFieldChange = (user_data) => {
    setUserDetails(user_data);
    dispatchAction(updateStateData(user_data));
  };

  React.useEffect(() => {
    fetchCompanies();
    loadCampaigns();
    // fetch user detail from the cookies
    const checkUserDetail = checkUserDetailsFromCookie();
    if (checkUserDetail.success === true) {
      setUserData(checkUserDetail.user_detail);
    }
    //set roles list content and their corresponding permissions
    const rolesList = {
      Retailer: {
        overview: {
          can_view: true,
          can_edit: false,
        },
        campaigns: {
          can_view: true,
          can_edit: true,
          can_request: true,
        },
        retail_analytics: {
          can_view: true,
          can_edit: false,
        },
        infrastructure: {
          can_view: false,
          can_edit: false,
        },
        user_management: {
          can_view: false,
          can_edit: false,
        },
      },
      Brand: {
        overview: {
          can_view: false,
          can_edit: false,
        },
        campaigns: {
          can_view: true,
          can_edit: false,
          can_request: false,
        },
        retail_analytics: {
          can_view: false,
          can_edit: false,
        },
        infrastructure: {
          can_view: false,
          can_edit: false,
        },
        user_management: {
          can_view: false,
          can_edit: false,
        },
      },
      Admin: {
        overview: {
          can_view: true,
          can_edit: true,
        },
        campaigns: {
          can_view: true,
          can_edit: true,
          can_request: true,
        },
        retail_analytics: {
          can_view: true,
          can_edit: true,
        },
        infrastructure: {
          can_view: true,
          can_edit: true,
        },
        user_management: {
          can_view: true,
          can_edit: true,
        },
      },
      Customized: {
        overview: {
          can_view: false,
          can_edit: false,
        },
        campaigns: {
          can_view: false,
          can_edit: false,
          can_request: false,
        },
        retail_analytics: {
          can_view: false,
          can_edit: false,
        },
        infrastructure: {
          can_view: false,
          can_edit: false,
        },
        user_management: {
          can_view: false,
          can_edit: false,
        },
      },
    };

    dispatchAction(
      updateStateData({
        role_list: rolesList,
      })
    );
    setSelectedRolePermissions(userInfo.user_permissions);
    dispatchAction(updateStateData({ ...userInfo, newUserPermissions: userInfo.user_permissions }));
  }, []);

  return (
    <Dialog
      open={open}
      onClose={(e) => {
        e.preventDefault();
      }}
      fullWidth={true}
      maxWidth={size}
    >
      <DialogTitle> {usedFor === "update" ? "Update User" : "Add New User"}</DialogTitle>
      <DialogContent>
        <StyledMainBox component="main">
          <Box>
            {/* ------- username/first name/last name --------- */}
            <Stack direction={"row"} spacing={4}>
              {/* ------- user name -------- */}
              <TextInputField
                fieldRequired={true}
                elemId="userName"
                label="User Name"
                fieldName="user_name"
                showlabel={true}
                defaultVal={typeof userDetails.username !== "undefined" ? userDetails.username : ""}
                inputType={"text"}
                onChangeCall={(e) => {
                  e.preventDefault();
                  handleFieldChange({
                    ...userDetails,
                    username: e.target.value,
                  });
                }}
                fieldError={
                  typeof fieldError.user_name !== "undefined" && fieldError.user_name !== "" ? fieldError.user_name : ""
                }
              />
              {/* --------- email ---------- */}
              <TextInputField
                fieldRequired={true}
                elemId="email"
                label="Email"
                fieldName="email"
                showlabel={true}
                defaultVal={typeof userDetails.email !== "undefined" ? userDetails.email : ""}
                inputType={"text"}
                onChangeCall={(e) => {
                  e.preventDefault();
                  handleFieldChange({
                    ...userDetails,
                    email: e.target.value,
                  });
                }}
                fieldError={typeof fieldError.email !== "undefined" && fieldError.email !== "" ? fieldError.email : ""}
              />
              {/* --------- first name ---------- */}
              {/* <TextInputField
                fieldRequired={true}
                elemId="firstName"
                label="First Name"
                fieldName="first_name"
                showlabel={true}
                defaultVal={typeof userDetails.first_name !== "undefined" ? userDetails.first_name : ""}
                inputType={"text"}
                onChangeCall={(e) => {
                  e.preventDefault();
                  handleFieldChange({
                    ...userDetails,
                    first_name: e.target.value,
                  });
                }}
                fieldError={
                  typeof fieldError.first_name !== "undefined" && fieldError.first_name !== "" ? fieldError.first_name : ""
                }
              /> */}
              {/* --------- last name ---------- */}
              {/* <TextInputField
                fieldRequired={true}
                elemId="lastName"
                label="Last Name"
                fieldName="last_name"
                showlabel={true}
                defaultVal={typeof userDetails.last_name !== "undefined" ? userDetails.last_name : ""}
                inputType={"text"}
                onChangeCall={(e) => {
                  e.preventDefault();
                  handleFieldChange({
                    ...userDetails,
                    last_name: e.target.value,
                  });
                }}
                fieldError={
                  typeof fieldError.last_name !== "undefined" && fieldError.last_name !== "" ? fieldError.last_name : ""
                }
              /> */}
            </Stack>
            <Box height={40} />

            <Stack direction={"row"} spacing={4}>
              {/* ------------ company -------------- */}
              {typeof userData.user_type !== "undefined" && ["cyreen", "branch"].includes(userData.user_type) && (
                <SingleSelect
                  enableFullWidth={true}
                  label={"Company*"}
                  selectionCheck={(option, value) => option.label === value.label}
                  sortOptions={false}
                  options={typeof currentStateData.retailers_list !== "undefined" ? currentStateData.retailers_list : []}
                  defaultVal={
                    typeof userDetails.workspace_id !== "undefined"
                      ? { id: userDetails.workspace_id, label: userDetails.workspace_name }
                      : ""
                  }
                  onChangeCall={(selectedOption) => {
                    handleFieldChange({
                      ...userDetails,
                      workspace_id: selectedOption.id,
                      workspace_name: selectedOption.label,
                    });
                  }}
                  fieldError={
                    typeof fieldError.user_company !== "undefined" && fieldError.user_company !== ""
                      ? fieldError.user_company
                      : ""
                  }
                />
              )}

              {/* --------- Role --------- */}
              <SingleSelect
                enableFullWidth={true}
                label={"User role*"}
                selectionCheck={(option, value) => option.label === value.label}
                sortOptions={false}
                options={
                  Object.keys(roleAndPermissions).length !== 0
                    ? Object.keys(roleAndPermissions).map((roleName) => {
                        return { value: roleName, label: roleName };
                      })
                    : []
                }
                defaultVal={
                  typeof userDetails.role_name !== "undefined"
                    ? { value: userDetails.role_name, label: userDetails.role_name }
                    : ""
                }
                onChangeCall={(selectedOption) => {
                  setSelectedRolePermissions(roleAndPermissions[selectedOption.value]);
                  handleFieldChange({
                    ...userDetails,
                    role_name: selectedOption.value,
                    newUserPermissions: roleAndPermissions[selectedOption.value],
                  });
                }}
                fieldError={
                  typeof fieldError.user_role !== "undefined" && fieldError.user_role !== "" ? fieldError.user_role : ""
                }
              />
            </Stack>
            {/* ----------- selection for retailers and campaigns ---------- */}
            {typeof userDetails.role_name !== "undefined" && userDetails.role_name === "Brand" && (
              <>
                <Box height={40} />
                <Stack direction={"row"} spacing={4}>
                  {/* --------- Campaign Selection --------- */}
                  <MultiSelect
                    label="Select Campaign*"
                    enableFullWidth={true}
                    options={
                      typeof currentStateData.campaign_list !== "undefined" &&
                      typeof currentStateData.campaign_list.campaign_name_obj !== "undefined"
                        ? currentStateData.campaign_list.campaign_name_obj
                        : []
                    }
                    defaultVal={
                      typeof userDetails.brand_campaign_selection !== "undefined"
                        ? userDetails.brand_campaign_selection
                        : []
                    }
                    selectionCheck={(option, value) => option.id === value.id}
                    onChangeCall={(selectedOption) => {
                      let selectedCampaigns = [];
                      selectedOption.forEach((option) => {
                        selectedCampaigns.push(option.id);
                      });

                      handleFieldChange({
                        ...userDetails,
                        campaign_id: selectedCampaigns,
                        brand_campaign_selection: selectedOption,
                      });
                    }}
                    // fieldError={
                    //   typeof fieldErrors.clip_campaign !== "undefined" && fieldErrors.clip_campaign !== ""
                    //     ? fieldErrors.clip_campaign
                    //     : ""
                    // }
                  />
                </Stack>
              </>
            )}
            {/* ------- permissions list -------- */}
            <Box height={40} />
            {typeof userDetails.role_name !== "undefined" && ["Customized"].includes(userDetails.role_name) && (
              <Stack>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    {/* ------ header row ------ */}
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left" className="text-wrapper">
                          Permission Scope
                        </StyledTableCell>
                        <StyledTableCell align="left" className="text-wrapper">
                          View
                        </StyledTableCell>
                        <StyledTableCell align="left" className="text-wrapper">
                          Edit
                        </StyledTableCell>
                        <StyledTableCell align="left" className="text-wrapper">
                          Request Report
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {restrictedAreas.map((scope) => {
                        return (
                          <TableRow key={scope + "_permission_row"}>
                            {/* ---------- permission scope --------- */}
                            <StyledTableCell component="td" scope="row" className="text-wrapper">
                              <StyledStack direction={"row"}> {capitalizeAndReplaceUnderScore(scope)} </StyledStack>
                            </StyledTableCell>
                            {/* ---------- view ---------- */}
                            <StyledTableCell component="td" scope="row" className="text-wrapper">
                              <StyledStack direction={"row"}>
                                <StyledCheckbox
                                  // disabled={selectedRole === "Customized" ? false : true}
                                  checked={
                                    typeof currentStateData.newUserPermissions !== "undefined" &&
                                    typeof currentStateData.newUserPermissions[scope] !== "undefined"
                                      ? currentStateData.newUserPermissions[scope].can_view
                                      : false
                                  }
                                  onChange={(e) => {
                                    savePermissions(scope, { can_view: e.target.checked });
                                  }}
                                />
                              </StyledStack>
                            </StyledTableCell>
                            {/* -------- Edit --------- */}
                            <StyledTableCell component="td" scope="row" className="text-wrapper">
                              {!["overview", "retail_analytics"].includes(scope) ? (
                                <StyledStack direction={"row"}>
                                  <StyledCheckbox
                                    // disabled={selectedRole === "Customized" ? false : true}
                                    checked={
                                      typeof currentStateData.newUserPermissions !== "undefined" &&
                                      typeof currentStateData.newUserPermissions[scope] !== "undefined"
                                        ? currentStateData.newUserPermissions[scope].can_edit
                                        : false
                                    }
                                    onChange={(e) => {
                                      //view permission will automatically be true if edit is true.
                                      let selections = {
                                        can_edit: e.target.checked,
                                      };
                                      if (e.target.checked) {
                                        selections = {
                                          ...selections,
                                          can_view: true,
                                        };
                                      }
                                      savePermissions(scope, selections);
                                    }}
                                  />
                                </StyledStack>
                              ) : (
                                ""
                              )}
                            </StyledTableCell>
                            {/* -------- request report ------- */}
                            <StyledTableCell>
                              {scope === "campaigns" ? (
                                <StyledStack direction={"row"}>
                                  <StyledCheckbox
                                    checked={
                                      typeof currentStateData.newUserPermissions !== "undefined" &&
                                      typeof currentStateData.newUserPermissions[scope] !== "undefined"
                                        ? currentStateData.newUserPermissions[scope].can_request
                                        : false
                                    }
                                    onChange={(e) => {
                                      //view permission will automatically be true if edit is true.
                                      let selections = {
                                        can_request: e.target.checked,
                                      };

                                      savePermissions(scope, selections);
                                    }}
                                  />
                                </StyledStack>
                              ) : (
                                ""
                              )}
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            )}
            {/* --------- checkbox to mark new user as cyreen user ----- */}
            {typeof userData.user_type !== "undefined" && userData.user_type === "cyreen" && (
              <>
                <Box height={40} />
                <Stack direction={"row"} spacing={4}>
                  <FormControlLabel
                    control={
                      <StyledCheckbox
                        checked={typeof userDetails.cyreen_user !== "undefined" ? userDetails.cyreen_user : false}
                        onChange={(e) => {
                          handleFieldChange({
                            ...userDetails,
                            cyreen_user: e.target.checked,
                          });
                        }}
                      />
                    }
                    label="Cyreen User"
                  />
                </Stack>
              </>
            )}
          </Box>
        </StyledMainBox>
      </DialogContent>
      <Box height={20} />
      <DialogActions>
        <Stack direction={"row"} spacing={2}>
          <CloseButton onClick={onClose} label="Cancel" icon={<Close />} />
          <ActionButton
            onClick={(e) => {
              e.preventDefault();
              setFieldError({});
              const userId = { id: typeof userDetails.id !== "undefined" ? userDetails.id : "" };
              saveUserInformation(usedFor, userId).then((info) => {
                if (info.success === true) {
                  const message = usedFor === "update" ? "User updated successfully" : "User added successfully";
                  toast(message, { type: "success" });
                  closeModal();
                } else {
                  setFieldError(info.errorMessages);
                  // toast("Sorry, something went wrong", { type: "error" });
                }
              });
            }}
            label={usedFor === "update" ? "Update" : "Save"}
            icon={<Save />}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default AddUser;

import React, { useState } from "react";
import { Box, Link, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import { StyledTableCell } from "../../../components/commonComponents/TabularLists/styles";
import { useSelector } from "react-redux";
import {
  HtmlTooltip,
  IconContainer,
  IconText,
  StyledDarkRedInformationTypography,
  StyledStack,
} from "../../../components/commonComponents/StyledComponents/styled";
import ProgressBar from "../../../components/commonComponents/Progress/ProgressBar";
import { Store, Edit, Visibility } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { loadClips, resetClipsData } from "../../../api_calls/clipRequests";
import { loadCampaigns } from "../../../api_calls/campaignRequests";
import AddNewClip from "../ModalContent/AddClip";
import PlayClip from "../ModalContent/PlayClip";
import { loadMoreData } from "../../../api_calls/utils";
import LoadMoreButton from "../../../components/commonComponents/LoadMore/LoadMoreButton";
import { clearfilters } from "../../../api_calls/campaignRequests";
import { checkUserDetailsFromCookie } from "../../../api_calls/userManagement";

const ClipsListing = () => {
  const campaignDetails = useSelector((state) => state.userSelections);

  const navigate = useNavigate();

  let listItems =
    typeof campaignDetails.clips_list_filter_applied !== "undefined" && campaignDetails.clips_list_filter_applied === true
      ? campaignDetails.filtered_clips_list.clips_list
      : typeof campaignDetails.clips_list !== "undefined"
      ? campaignDetails.clips_list
      : [];
  let clipsListitems = listItems.length !== 0 ? listItems.slice(0, campaignDetails.initial_load_count_for_clips) : [];

  const [openModal, setOpenModal] = useState(false);
  const [clipInfo, setClipInfo] = useState(false);
  const [playClipModal, setPlayClip] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const checkUserDetails = checkUserDetailsFromCookie();

  const closeModal = () => {
    resetClipsData();
    setOpenModal(false);
  };

  //initially only 10 results will be loaded, further 10 will be loaded on click of load more button
  const loadMore = () => {
    loadMoreData("initial_load_count_for_clips");
  };

  React.useEffect(() => {
    if (checkUserDetails.success === true) {
      setUserInfo(checkUserDetails.user_detail);
    }
    // campaignDetails.loading = true;
    loadClips().then((res) => {
      loadCampaigns();
    });

    clearfilters();
  }, []);

  return clipsListitems.length !== 0 ? (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          {/* ------ header row ------ */}
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" className="text-wrapper">
                #
              </StyledTableCell>
              <StyledTableCell align="left" className="text-wrapper">
                Name
              </StyledTableCell>
              <StyledTableCell align="left" className="text-wrapper">
                Campaign
              </StyledTableCell>
              <StyledTableCell align="left" className="text-wrapper">
                Progress
              </StyledTableCell>
              <StyledTableCell align="left" className="text-wrapper">
                Owner
              </StyledTableCell>
              <StyledTableCell align="left" className="text-wrapper">
                Stores
              </StyledTableCell>
              <StyledTableCell align="left" className="text-wrapper">
                Impressions
              </StyledTableCell>
              {/* <StyledTableCell align="left" className="text-wrapper">
                Unlimited
              </StyledTableCell> */}
              <StyledTableCell align="left" className="text-wrapper">
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clipsListitems.map((clip, index) => {
              return (
                <TableRow key={index + "_campaign_row"}>
                  <StyledTableCell component="td" scope="row" className="text-wrapper">
                    {index + 1}
                  </StyledTableCell>
                  {/* --------- name ---------- */}
                  <StyledTableCell component="td" scope="row" className="text-wrapper wrapLargeText">
                    <StyledStack direction={"row"}>
                      <Link
                        href="#"
                        underline="none"
                        aria-label={"clip record for " + clip.campaign}
                        title={clip.name}
                        sx={{ color: "#AF3241" }}
                        onClick={(e) => {
                          e.preventDefault();
                          setClipInfo(clip);
                          setPlayClip(true);
                        }}
                      >
                        {clip.name}
                      </Link>
                    </StyledStack>
                  </StyledTableCell>
                  {/* ---------- campaign --------- */}
                  <StyledTableCell component="td" scope="row" className="text-wrapper wrapLargeText">
                    <StyledStack direction={"row"}>{clip.campaign}</StyledStack>
                  </StyledTableCell>
                  {/* ------- progress bar ---------- */}
                  <StyledTableCell component="td" scope="row" className="text-wrapper">
                    <ProgressBar startDate={clip.start_date} endDate={clip.end_date} />
                  </StyledTableCell>
                  {/* -------- company -------- */}
                  <StyledTableCell component="td" scope="row" className="text-wrapper wrapLargeText">
                    <StyledStack direction={"row"}>{clip.clip_company_name}</StyledStack>
                  </StyledTableCell>
                  {/* ---------- stores ---------- */}
                  <StyledTableCell component="td" scope="row" className="text-wrapper">
                    <StyledStack direction={"row"}>
                      <HtmlTooltip
                        title={
                          clip.selected_store_options.length !== 0
                            ? clip.selected_store_options.map((store) => store.label).join(", ")
                            : "Stores"
                        }
                      >
                        <IconContainer>
                          <Store sx={{ color: "#AF3241" }} />
                          <IconText variant="body2"> {clip.stores} </IconText>
                        </IconContainer>
                      </HtmlTooltip>
                    </StyledStack>
                  </StyledTableCell>
                  {/* -------- impression --------- */}
                  <StyledTableCell component="td" scope="row" className="text-wrapper">
                    <StyledStack direction={"row"}>
                      <HtmlTooltip title={"Impressions"}>
                        <IconContainer>
                          <Visibility sx={{ color: "#AF3241" }} />
                          {clip.unlimited === true ? (
                            <IconText variant="body2"> Unlimited </IconText>
                          ) : (
                            <IconText variant="body2"> {clip.campaign_contacts} </IconText>
                          )}
                        </IconContainer>
                      </HtmlTooltip>
                    </StyledStack>
                  </StyledTableCell>
                  {/* ----------- unlimited ---------- */}
                  {/* <StyledTableCell component="td" scope="row" className="text-wrapper">
                    <StyledStack direction={"row"}>
                      <StyledSwitch checked={clip.unlimited} />
                    </StyledStack>
                  </StyledTableCell> */}

                  {/* ----------- action ---------- */}
                  <StyledTableCell component="td" scope="row" className="text-wrapper">
                    <StyledStack direction={"row"} spacing={2}>
                      {typeof userInfo.current_workspace !== "undefined" &&
                        userInfo.current_workspace.id === clip.clip_company_id && (
                          <HtmlTooltip title="Edit">
                            <IconContainer>
                              <a
                                href="#editClip"
                                aria-label={"edit clip for " + clip.campaign}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setOpenModal(true);
                                  setClipInfo(clip);
                                }}
                              >
                                <Edit sx={{ color: "#AF3241" }} />
                              </a>
                            </IconContainer>
                          </HtmlTooltip>
                        )}
                    </StyledStack>
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* --------- record count info --------- */}
      <Box mt={2} id="infoText">
        <StyledDarkRedInformationTypography variant="body2">
          Showing {clipsListitems.length} out of {listItems.length} results.
        </StyledDarkRedInformationTypography>
      </Box>
      {/* ---------- Load more button ----------- */}
      <Box height={20} />
      {clipsListitems.length < listItems.length && <LoadMoreButton clickAction={loadMore} />}

      {/* ------- edit clip modal ---------- */}
      {openModal && (
        <AddNewClip
          open={openModal}
          onClose={closeModal}
          size={"md"}
          closeModal={closeModal}
          usedFor={"update"}
          clipInfo={clipInfo}
        />
      )}
      {/* ------ play clip ------ */}
      {playClipModal === true && (
        <PlayClip
          open={playClipModal}
          onClose={() => {
            setPlayClip(false);
          }}
          size={"md"}
          clipInfo={clipInfo}
          closeModal={() => {
            setPlayClip(false);
          }}
        />
      )}
    </Box>
  ) : (
    <Box>
      {typeof campaignDetails.loading !== "undefined" && campaignDetails.loading === true ? (
        <p>Please wait while we are loading results...</p>
      ) : (
        <p>No clips found</p>
      )}
    </Box>
  );
};

export default ClipsListing;
